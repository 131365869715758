@import "src/styles/mixins";

.container {

}

.bullets {
  &__list {
    @include flex($direction: column);
    gap: 8px;
    padding: 16px 12px;
    border-radius: 12px;

    border: 2px solid var(--yellow-accent);
    background: #FFFCED;
    margin-bottom: 20px;
  }

  &__item {
    @include flex();
    gap: 4px;
  }

  &__dot {
    flex-shrink: 0;
    margin: 5px;
    @include circle(4px);
    background: var(--black);
  }
}

.points {
  &__list {
    @include flex($direction: column);
    gap: 12px;
    margin-bottom: 24px;
  }

  &__item {
    @include flex();
    gap: 10px;
  }

  &__icon {
    flex-shrink: 0;
    @include square(18px)
  }
}

.total {
  opacity: 0.5;
  margin-bottom: 12px;
}

.disclaimer {
  opacity: 0.5;
}

.link {
  text-decoration: underline;
}

