@import "src/styles/mixins";

.footer {
  &__links {
    @include flex($align: center, $justify: center);
    gap: 28px;

    margin-bottom: 12px;
  }

  &__terms_link {
    position: relative;

    display: block;

    font-weight: 500;
    font-size: 14px;

    &:not(:last-child) {
      &::after {
        position: absolute;
        top: 50%;
        right: -14px;

        display: block;

        background-color: var(--black);
        content: '';
        pointer-events: none;

        @include circle(3px);

      }
    }
  }

  &__email {
    margin-bottom: 24px;

    opacity: 0.6;

    color: var(--grey-dark);

    font-weight: 400 !important;

    text-align: center;
  }

  &__copyright {
    padding-top: 24px;

    opacity: 0.6;

    color: var(--grey-dark);

    border-top: 1px solid var(--black-30);

    font-weight: 400 !important;

    text-align: center;
  }

  .link {
    text-decoration: underline;
  }
}
