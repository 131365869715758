@import "src/styles/fonts";

.form {
  &__fields {
    margin-bottom: 20px;

    display: grid;
    grid-auto-columns: 1fr 1fr;
    grid-gap: 8px;
    grid-template-areas: 'number number' 'expiry cvc' 'name name';
  }

  &__input {
    padding: 16px 12px;

    background-color: var(--blue-10) !important;

    border: 1px solid var(--black-5);
    border-radius: 8px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      background-color: var(--blue-10) !important;

      box-shadow: 0 0 0 1000px var(--blue-10) inset !important;
    }

    &.card_number {
      grid-area: number;
    }

    &.card_expiry {
      grid-area: expiry;
    }

    &.card_cvc {
      grid-area: cvc;
    }

    &.card_name {
      grid-area: name;

      color: var(--black);

      font-weight: 400;
      font-family: $primary-font-family;

      &::placeholder {
        opacity: 1;
      }
    }
  }

  &__button {
    height: 55px;

    color: var(--black);
    background: var(--yellow-accent);

    border: none;
    border-radius: 40px;

    transition: background 300ms, color 300ms, opacity 300ms;

    &:disabled {
      opacity: 0.3;

      color: var(--white-100);
      background: var(--black);
    }
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: -50px;

  transform: translate(0, -50%);
}
