:root {
  --white-100: #fff;
  --white-10: rgb(255 255 255 / 10%);
  --white-10-on-black: #191919;
  --white-20: rgb(255 255 255 / 20%);
  --white-50: rgb(255 255 255 / 50%);
  --black: #000;
  --grey-dark: #1B1A1F;
  --grey-ultralight: #ECECEC;
  --grey-light: #F9F9F9;
  --gray: #EEEDED;
  --input-color: #221435;
  --input-bg-color: #F2F2F7;
  --red-color: #FF5151;
  --red-20: #FFE4E4;
  --green-color: #3AB56C;
  --yellow-light: #FFE976;
  --yellow-accent: #FFCF2E;
  --line-color: #D9D9D9;
  --blue-10: #F1F3F9;
  --blue-50: #5773D6;
  --blue: #3759d5;
  --gray-5: rgb(0 0 0 / 5%);
  --gray-20: #F3F4F7;
  --primary-100: #8269E5;
  --black-100: #000;
  --black-70: rgb(0 0 0 / 70%);
  --black-60: rgb(0 0 0 / 60%);
  --black-50: rgb(0 0 0 / 50%);
  --black-30: rgb(0 0 0 / 30%);
  --black-10: rgb(0 0 0 / 10%);
  --black-5: rgb(0 0 0 / 5%);
  --green-100: #39A765;
  --green-10: rgba(57, 167, 101, 0.10);
  --orange-100: #FF7D1B;
  --orange-90: #F90;
  --orange-30: #FFAB7C;
  --orange-orange-gradient: linear-gradient(92deg, #FF7E20 10.74%, #FF5C00 88.11%);
}
