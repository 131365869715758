.button {
  width: 100%;
  height: 60px;

  padding: 0 20px;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid var(--grey-dark);

  border-radius: 12px;
  outline: none;

  font-weight: 700;
  font-size: 18px;
  font-style: normal;
  line-height: normal;

  transition: opacity 250ms;

  cursor: pointer;

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.primary {
  color: var(--grey-dark);
  background-color: var(--white-100);
}

.secondary {
  color: var(--white-100);
  background-color: var(--grey-dark);
}

.accent {
  color: var(--white-100);
  background: var(--orange-orange-gradient);

  border: 1px solid var(--orange-30);
}

.icon {
  position: absolute;
  top: 50%;
  right: 22px;

  display: flex;

  transform: translate(0, -50%);
}

.small {
  height: 46px;

  padding: 6px 0;
}
