@import "src/styles/fonts";

.form {
  &__fields {
    margin-bottom: 20px;

    display: grid;
    grid-auto-columns: 1fr 1fr;
    grid-gap: 12px 10px;
    grid-template-areas: 'number number' 'expiry cvc' 'email email';
  }

  &__fields_item {
    &.card_number {
      grid-area: number;
    }

    &.card_expiry {
      grid-area: expiry;
    }

    &.card_cvc {
      grid-area: cvc;
    }

    &.email {
      grid-area: email;

      .form__input {
        color: var(--black);

        font-weight: 400;
        font-family: $primary-font-family;

        &::placeholder {
          opacity: 1;
        }
      }
    }
  }

  &__label {
    margin-bottom: 4px;
  }

  &__input {
    width: 100%;
    padding: 14px 12px;

    background-color: var(--white-100) !important;

    border-radius: 10px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      background-color: var(--blue-10) !important;

      box-shadow: 0 0 0 1000px var(--blue-10) inset !important;
    }
  }

  &__button {
    height: 55px;

    color: var(--black);
    background: var(--yellow-accent);

    border: none;
    border-radius: 40px;

    transition: background 300ms, color 300ms, opacity 300ms;

    &:disabled {
      opacity: 0.3;

      color: var(--white-100);
      background: var(--black);
    }
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: -50px;

  transform: translate(0, -50%);
}
